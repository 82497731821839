import React, { useState } from "react";
import Carousel from "react-elastic-carousel";
import "./home.css";
import background from "../assets/Background.png";
import EVlogo from "../assets/ev-logo.png";
import engLogo from "../assets/eng.png";
import thaiLogo from "../assets/th.png";
import EVBackground from "../assets/ev-bg.png";
import locationIcon from "../assets/ic-location.png";
import calendarIcon from "../assets/ic-calendar.png";
import nightIcon from "../assets/ic-night.png";
import promo1 from "../assets/promo-1.png";
import promo2 from "../assets/promo-2.png";
import promo3 from "../assets/promo-3.png";
import recommend1 from "../assets/recommend-1.png";
import recommend2 from "../assets/recommend-2.png";
import recommend3 from "../assets/recommend-3.png";
import recommend4 from "../assets/recommend-4.png";
import recommend5 from "../assets/recommend-5.png";
import recommend6 from "../assets/recommend-6.png";
import popPlace1 from "../assets/pop1.png";
import popPlace2 from "../assets/pop2.png";
import popPlace3 from "../assets/pop3.png";
import copyrightIcon from "../assets/ic-copyright.png";
import fbIcon from "../assets/Facebook.png";
import igIcon from "../assets/Instagram.png";
import ytIcon from "../assets/Youtube.png";

function Homepage() {

    const breakPoints_promo = [
        { width: 200, itemsToShow: 1 },
        { width: 600, itemsToShow: 2 },
        { width: 1000, itemsToShow: 2.5 },
    ];

    const breakPoints_recommend = [
        { width: 200, itemsToShow: 2 },
        { width: 350, itemsToShow: 3 },
        { width: 568, itemsToShow: 4 },
    ];

    const [language, setLanguage] = useState("Eng");

    const [showChangeLan, setShowChangeLan] = useState(false)

    var curr = new Date();
    var date = curr.toISOString().substr(0, 10);

    return (
        <div className="w-screen h-auto">
            <img className="w-full h-full absolute" src={background} style={{ zIndex: -1 }} />
            <div className="w-full flex justify-between" style={{ height: 48, padding: "16px 25px 0px 25px" }}>
                <div>
                    <img alt="logo" src={EVlogo} style={{ width: 101, height: 45 }} />
                </div>
                <div className="flex">
                    <div className="relative">
                        <img className="cursor-pointer" onClick={() => setShowChangeLan(!showChangeLan)} alt="language" src={language == "Eng" ? engLogo : thaiLogo}
                            style={{ width: 40, height: 40, marginRight: 16 }} />
                        <div className={showChangeLan ? "absolute" : "hidden"} style={{ width: 140, height: "auto", left: 20, backgroundColor: "white", 
                        zIndex:2, padding: 5}}>
                            <div className="cursor-pointer flex justify-start items-center hover-blue"  onClick={() => {setLanguage("Eng");setShowChangeLan(false)}}>
                                <img alt="language" src={engLogo}
                                    style={{ width: 40, height: 40, marginRight: 16 }} />
                                <div style={{ lineHeight: 3 }}>English</div>
                            </div>
                            <div className="cursor-pointer flex justify-start items-center hover-blue"  onClick={() => {setLanguage("Thai");setShowChangeLan(false)}} >
                                <img alt="language" src={thaiLogo}
                                    style={{ width: 40, height: 40, marginRight: 16 }} />
                                <div style={{ lineHeight: 3 }}>Thai</div>
                            </div>
                        </div>
                    </div>
                    <div onClick={() => { }} className="text-center cursor-pointer"
                        style={{
                            height: 40, width: 181, padding: "10px 20px", marginRight: 16,
                            borderRadius: 20, border: "1px solid #1D4289", cursor: "pointer"
                        }}>
                        <div style={{ fontSize: 14, fontWeight: 500 }}>Create an account</div>
                    </div>
                    <div onClick={() => { }} className="text-center cursor-pointer"
                        style={{
                            height: 40, width: 101, padding: "10px 20px",
                            borderRadius: 20, backgroundColor: "#1D4289", cursor: "pointer"
                        }}>
                        <div style={{ fontSize: 14, fontWeight: 500, color: "white" }}>Sign in</div>
                    </div>
                </div>
            </div>
            <div className="bg-cover h-auto flex justify-center" style={{ marginTop: 36, padding: "50px 0px", backgroundImage: `url(${EVBackground})` }}>
                <div className="w-full" style={{
                    height: 244, padding: "20px 16px", borderRadius: 24, maxWidth: 1400,
                    background: "linear-gradient(360deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.35) 100%)",
                    backdropFilter: "blur(16px)"
                }}>
                    <div style={{ marginLeft: 24, fontSize: 18, fontWeight: 500, color: "white" }}>
                        Book your vacation
                    </div>
                    <div className="w-full flex justify-center" style={{ height: 106, border: "2px solid white", borderRadius: 16 }}>
                        <div style={{ width: "28.5%", borderRight: "2px solid white", padding: "8px 16px" }}>
                            <div style={{ marginLeft: 8, fontSize: 14, fontWeight: 500, color: "white" }}>Hotel/City/Place to go</div>
                            <label className="relative" for="location" style={{ marginTop: 4 }}>
                                <img src={locationIcon} className="absolute" style={{ left: 8, top: 1, width: 20, height: 20 }} />
                                <input className="w-full" id="location" type="text" style={{ height: 40, padding: "8px 0px 8px 48px", borderRadius: 8 }} value="Bangkok" />
                            </label>
                        </div>
                        <div style={{ width: "24.5%", borderRight: "2px solid white", padding: "8px 16px" }}>
                            <div style={{ marginLeft: 8, fontSize: 14, fontWeight: 500, color: "white" }}>Check-in Date</div>
                            <label className="relative" for="calendar" style={{ marginTop: 4 }}>
                                <img src={calendarIcon} className="absolute" style={{ left: 8, top: 1, width: 20, height: 20 }} />
                                <input className="w-full" id="calendar" type="date" style={{ height: 40, padding: "8px 0px 8px 48px", borderRadius: 8 }} defaultValue={date} />
                            </label>
                            {/*<div style={{ marginLeft: 8, marginTop: 4, fontSize: 14, color: "#333333" }}>Check-out: 22 December 2021</div>*/}
                        </div>
                        <div style={{ width: "18.25%", borderRight: "2px solid white", padding: "8px 16px" }}>
                            <div style={{ marginLeft: 8, fontSize: 14, fontWeight: 500, color: "white" }}>Duration</div>
                            <label className="relative" for="duration" style={{ marginTop: 4 }}>
                                <img src={nightIcon} className="absolute" style={{ left: 8, top: 1, width: 20, height: 20 }} />
                                <select className="w-full" id="duration" style={{ height: 40, padding: "8px 0px 8px 48px", borderRadius: 8 }} defaultValue="1 Night">
                                    <option>1 Night</option>
                                    <option>2 Nights</option>
                                    <option>3 Nights</option>
                                    <option>4 Nights</option>
                                    <option>5 Nights</option>
                                </select>
                            </label>
                        </div>
                        <div style={{ width: "28.5%", padding: "8px 16px" }}>
                            <div style={{ marginLeft: 8, fontSize: 14, fontWeight: 500, color: "white" }}>Guests and Rooms</div>
                            <div className="bg-white w-full flex space-between" style={{ height: 40, borderRadius: 8, padding: "8px 0px 8px 20px" }}>
                                <div className="w-1/3">
                                    <label for="adult" className="relative">
                                        <input id="adult" className="w-full" style={{ borderRight: "1px solid #c4c4c4", paddingLeft: 20 }} type="number" defaultValue="1" min="1" />
                                        <div className="absolute" style={{ top: 0, right: 14 }}>Adults</div>
                                    </label>
                                </div>
                                <div className="w-1/3">
                                    <label for="children" className="relative">
                                        <input id="children" className="w-full" style={{ borderRight: "1px solid #c4c4c4", paddingLeft: 14 }} type="number" defaultValue="0" min="0" />
                                        <div className="absolute" style={{ top: 0, right: 14 }}>Children</div>
                                    </label>
                                </div>
                                <div className="w-1/3">
                                    <label for="room" className="relative">
                                        <input id="room" className="w-full" style={{ borderRight: "1px solid #c4c4c4", paddingLeft: 20 }} type="number" defaultValue="1" min="1" />
                                        <div className="absolute" style={{ top: 0, right: 20 }}>Room</div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="float-right text-center" style={{
                        marginTop: 8, width: 151, height: 47, color: "white", cursor: "pointer",
                        backgroundColor: "#1D4289", borderRadius: 24, fontSize: 18, padding: "10px 0px"
                    }}>
                        SEARCH
                    </div>
                </div>
            </div>
            <div className="w-full text-center" style={{ marginTop: 80, padding: "0px 10%" }}>
                Promotion
                <div style={{ marginTop: 24 }}>
                    <Carousel breakPoints={breakPoints_promo} disableArrowsOnEnd={false}>
                        <div key="promo1" style={{ width: 360, height: 140 }}><img src={promo1} className="w-full h-full" /></div>
                        <div key="promo2" style={{ width: 360, height: 140 }}><img src={promo2} className="w-full h-full" /></div>
                        <div key="promo3" style={{ width: 360, height: 140 }}><img src={promo3} className="w-full h-full" /></div>
                    </Carousel>
                </div>
            </div>
            <div className="h-auto text-center" style={{ marginTop: 50, padding: "0px 20%" }}>
                Recommended Destination
                <div style={{ marginTop: 24 }}>
                    <Carousel breakPoints={breakPoints_recommend} disableArrowsOnEnd={false} itemsToScroll="2">
                        <div key="recommend1" className="relative flex justify-center text-center" style={{ width: 170, height: 192 }}>
                            <img className="w-full" src={recommend1} style={{ width: 160, height: 160 }} />
                            <div className="absolute bottom-0" style={{ fontSize: 16 }}>Pattaya</div>
                        </div>
                        <div key="recommend2" className="relative flex justify-center text-center" style={{ width: 170, height: 192 }}>
                            <img className="w-full" src={recommend2} style={{ width: 160, height: 160 }} />
                            <div className="absolute bottom-0" style={{ fontSize: 16 }}>Bangkok</div>
                        </div>
                        <div key="recommend3" className="relative flex justify-center text-center" style={{ width: 170, height: 192 }}>
                            <img className="w-full" src={recommend3} style={{ width: 160, height: 160 }} />
                            <div className="absolute bottom-0" style={{ fontSize: 16 }}>Ayutthaya</div>
                        </div>
                        <div key="recommend4" className="relative flex justify-center text-center" style={{ width: 170, height: 192 }}>
                            <img className="w-full" src={recommend4} style={{ width: 160, height: 160 }} />
                            <div className="absolute bottom-0" style={{ fontSize: 16 }}>Phuket</div>
                        </div>
                        <div key="recommend5" className="relative flex justify-center text-center" style={{ width: 170, height: 192 }}>
                            <img className="w-full" src={recommend5} style={{ width: 160, height: 160 }} />
                            <div className="absolute bottom-0" style={{ fontSize: 16 }}>Chiangmai</div>
                        </div>
                        <div key="recommend6" className="relative flex justify-center text-center" style={{ width: 170, height: 192 }}>
                            <img className="w-full" src={recommend6} style={{ width: 160, height: 160 }} />
                            <div className="absolute bottom-0" style={{ fontSize: 16 }}>Krabi</div>
                        </div>
                    </Carousel>
                </div>
            </div>
            <div className="h-auto text-center" style={{ marginTop: 50, marginBottom: 236 }}>
                Popular Places
                <div style={{ marginTop: 24, fontSize: 14, color: "#565656" }}>
                    Your peace of mind doesn’t have to be tied to where everyone else is.<br />
                    We have a good number of travel and relocation destinations.<br />
                    Take your time and find the perfect one for you.
                </div>
                <div className="flex justify-center h-auto" style={{ marginTop: 44 }}>
                    <div className="relative flex justify-center" style={{
                        marginRight: 37, width: 280, height: 200, fontWeight: 600,
                        color: "#FFFFFF"
                    }}>
                        <img className="w-full h-full" src={popPlace1} />
                        <div className="absolute" style={{ bottom: 31, fontSize: 20, textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}>
                            Leenta Hua Hin<br />Resort&Spa
                        </div>
                        <div className="absolute" style={{ bottom: 9, fontSize: 14 }}>
                            Huahin
                        </div>
                    </div>
                    <div className="relative flex justify-center" style={{
                        marginRight: 37, width: 280, height: 200, fontWeight: 600,
                        color: "#FFFFFF"
                    }}>
                        <img className="w-full h-full" src={popPlace2} />
                        <div className="absolute text-center" style={{ bottom: 31, fontSize: 20, textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}>
                            W22 by Burasari
                        </div>
                        <div className="absolute text-center" style={{ bottom: 9, fontSize: 14 }}>
                            Chinatown
                        </div>
                    </div>
                    <div className="relative flex justify-center" style={{
                        width: 280, height: 200, fontWeight: 600, color: "#FFFFFF"
                    }}>
                        <img className="w-full h-full" src={popPlace3} />
                        <div className="absolute" style={{ bottom: 31, fontSize: 20, textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}>
                            Conrad Bangkok
                        </div>
                        <div className="absolute" style={{ bottom: 9, fontSize: 14 }}>
                            Sukhumvit
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full bottom-0 flex justify-evenly" style={{
                height: 80, backgroundColor: "#1D4289", padding: "30px 0px", color: "white", fontSize: 18
            }}>
                <div className="flex space-between">
                    <img alt="copyright" src={copyrightIcon} style={{ width: 14, height: 14, marginRight: 7, marginTop: 3 }} />
                    All right Reserved
                </div>
                <div className="flex space-between">
                    <div style={{ marginRight: 60 }}>Contact</div>
                    <div style={{ marginRight: 60 }}>Privacy</div>
                    <div style={{ marginRight: 60 }}>Term</div>
                    <div>About</div>
                </div>
                <div className="flex space-between">
                    <img src={fbIcon} style={{ width: 50, height: 50, marginRight: 15, marginTop: -10 }} />
                    <img src={igIcon} style={{ width: 50, height: 50, marginRight: 15, marginTop: -10 }} />
                    <img src={ytIcon} style={{ width: 50, height: 50, marginRight: 15, marginTop: -10 }} />
                </div>
            </div>
        </div>
    );
}

export default Homepage;